import React from 'react';
import './App.css';
import Home from './components/Home';
import Service from './components/Service';
import LogoSlider from './components/LogoSlider';
import ContactUs from './components/ContactUs';
import Portfolio from './components/Portfolio';
import Testimonials from './components/Testimonials';
import ProjectsCount from './components/ProjectsCount';
import ContactUsForm from './components/ContactUsForm';

function App() {
  return (
    // <Router>
    //   <Routes>
    //     <Route path='/' element={<Service />} />
    //     {/* <Route path='/service' element={<Service />} /> */}
    //   </Routes>
    // </Router>
    <div className="flex-1 w-full bg-black">
      
        <Home />
        <LogoSlider />
      
      
        <Service />
    
        <Portfolio/>
        <ProjectsCount />
      
        
        <Testimonials/>

        
      
      
      
        <ContactUs />
        
      
    </div>
  );
}

export default App;
