import React from 'react';
import "./Testimonial.css";

function Testimonials() {
    return (
        <div className='flex flex-col items-center justify-center md:mt-[240px] md:mb-20 lg:mx-48 mt-[100px]'>
            <div className="flex flex-row justify-center w-full font-dela-gothic-one text-center gradient-text sm:text-[50px] lg:text-[76px] xl:text-[90px] text-[35px]">
                <span className="gradient-text">Testimonials</span>
            </div>
            {/* Mobile responsive */}
            <div className='flex flex-col w-screen overflow-hidden h-[600px] px-10 md:hidden'>
                <div className='group pt-[470px] bg-white rounded-lg h-[450px] w-full overflow-hidden hover:bg-gradient-to-br flex justify-center flex-col items-center from-black relative mt-16'>
                    <div className='flex-col h-[400px] justify-center items-center flex pb-[490px]'>

                        <div className='flex flex-row justify-center w-full'>
                            <img src='images/Chamikara.png' alt='' width={60} height={60} className='border-2 border-black rounded-full group-hover:border-white' />
                        </div>
                        <div className='mt-4 text-base font-bold text-center group-hover:text-white w-[250px]'>
                        Pleasure to Recommend!
                        </div>
                        <div>
                            <img src='images/rating.svg' alt='' width={150} height={8} />
                        </div>
                        <div className='w-[250px] group-hover:text-white text-black text-center text-xs'>
                        It's a great pleasure to recommend Rotaframe Technology Pvt (Ltd) for their excellent software solutions and exceptional customer support. The team at Rotaframe Technology Pvt (Ltd) is knowledgeable, approachable, and truly concerned about the success of their clients. I have known their team members for a long time and I can attest to their dedication and expertise. Rotaframe Technology Pvt Ltd would leverage its clients' experience to provide better, well-supported, and efficient software solutions.
                        </div>
                        <div className='mt-0 text-sm font-semibold group-hover:text-white text-center w-[250px] pt-5'>Chamikara Kariyapperuma</div>
                        <div className='text-xs font-light group-hover:text-white'>Quality Enginner at Sysco LABS</div>
                    </div>
                </div>
                <div className='w-full h-[350px] bg-white mt-2 flex flex-col' style={{ zIndex: 19 }}>
                    
                </div>
                <div style={{ zIndex: 20 }} className='absolute flex flex-row w-screen h-[100px] bg-gradient-to-t mt-[500px]'>
                    
                </div>
            </div>


            {/* Desktop View */}

            <div className='hidden w-[1440px] h-[640px] md:flex justify-center items-center overflow-hidden mt-24'>
            <div className='absolute flex flex-row w-full h-[640px] bg-gradient-to-t' style={{zIndex:19}}></div>
                <div className='flex flex-row gap-8 mt-[400px]'>


                
                    {/* Sliding col start */}
                    <div className='flex-col hidden gap-10 lg:flex'>

                        
                        {/* Card */}
                        <div className='group bg-white rounded-lg h-[550px] w-[400px] overflow-hidden hover:bg-gradient-to-br flex justify-center flex-col items-center from-black relative mt-16'>
                            <div className='flex-col h-[320px] justify-center items-center flex'>

                                <div className='flex flex-row justify-center w-full'>
                                    <img src='images/Chamikara.png' alt='' width={70} height={70} className='border-2 border-black rounded-full group-hover:border-white' />
                                </div>
                                <div className='mt-4 text-xl font-bold text-center group-hover:text-white'>
                                Pleasure to Recommend!
                                </div>
                                <div>
                                    <img src='images/rating.svg' alt='' width={200} height={8} />
                                </div>
                                <div className='w-[300px] group-hover:text-white text-black text-center text-sm'>
                                It's a great pleasure to recommend Rotaframe Technology Pvt (Ltd) for their excellent software solutions and exceptional customer support. The team at Rotaframe Technology Pvt (Ltd) is knowledgeable, approachable, and truly concerned about the success of their clients. I have known their team members for a long time and I can attest to their dedication and expertise. Rotaframe Technology Pvt Ltd would leverage its clients' experience to provide better, well-supported, and efficient software solutions.
                                </div>
                                <div className='mt-3 text-base font-semibold group-hover:text-white'>Chamikara Kariyapperuma
</div>
                                <div className='text-xs font-light group-hover:text-white'>Quality Enginner at Sysco LABS
</div>
                            </div>
                        </div>

                        {/* Card */}
                        <div className='group bg-white rounded-lg h-[430px] w-[400px] overflow-hidden hover:bg-gradient-to-br flex justify-center flex-col items-center from-black relative'>
                            <div className='flex-col h-[320px] justify-center items-center flex'>

                                <div className='flex flex-row justify-center w-full'>
                                    <img src='images/HiranBehance.png' alt='' width={70} height={70} className='border-2 border-black rounded-full group-hover:border-white' />
                                </div>
                                <div className='mt-2 text-xl font-semibold text-center group-hover:text-white'>
                                    Superb!
                                </div>
                                <div>
                                    <img src='images/rating.svg' alt='' width={200} height={8} />
                                </div>
                                <div className='w-[230px] group-hover:text-white text-black text-center text-base'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                                </div>
                                <div className='mt-3 text-base font-medium group-hover:text-white'>Hiran Welagedara</div>
                                <div className='text-xs font-medium group-hover:text-white'>Co-Founder</div>
                            </div>
                        </div>
                    </div>
                    {/* Sliding col end */}

                    {/* Sliding col start */}
                    <div className='flex-col hidden gap-10 lg:flex'>
                        {/* Card */}
                        <div className='group bg-white rounded-lg h-[520px] w-[400px] overflow-hidden hover:bg-gradient-to-br flex justify-center flex-col items-center from-black relative mt-16'>
                            <div className='flex-col h-[320px] justify-center items-center flex'>

                                <div className='flex flex-row justify-center w-full'>
                                    <img src='images/Akshitha.png' alt='' width={70} height={70} className='border-2 border-black rounded-full group-hover:border-white' />
                                </div>
                                <div className='mt-4 text-xl font-bold text-center group-hover:text-white'>
                                Highly Recommend!
                                </div>
                                <div>
                                    <img src='images/rating.svg' alt='' width={200} height={8} />
                                </div>
                                <div className='w-[340px] group-hover:text-white text-black text-center text-sm'>
                                I highly recommend partnering with Rotaframe Technology Pvt Ltd for their exceptional service and expertise in delivering top-quality software solutions. Their commitment to excellence is evident in every aspect of their work, making them an excellent choice for anyone seeking reliable and cutting-edge software development services. Rotaframe Technology Pvt Ltd consistently demonstrates a deep understanding of client needs and delivers innovative solutions that exceed expectations.
                                </div>
                                <div className='mt-3 text-base font-semibold group-hover:text-white'>Akshitha Sriyanjith</div>
                                <div className='text-xs font-light group-hover:text-white'>Software Engineer at Prime Engineering (pvt) Ltd
</div>
                            </div>
                        </div>

                        {/* Card */}
                        <div className='group bg-white rounded-lg h-[430px] w-[400px] overflow-hidden hover:bg-gradient-to-br flex justify-center flex-col items-center from-black relative'>
                            <div className='flex-col h-[320px] justify-center items-center flex'>

                                <div className='flex flex-row justify-center w-full'>
                                    <img src='images/HiranBehance.png' alt='' width={70} height={70} className='border-2 border-black rounded-full group-hover:border-white' />
                                </div>
                                <div className='mt-2 text-xl font-semibold text-center group-hover:text-white'>
                                    Superb!
                                </div>
                                <div>
                                    <img src='images/rating.svg' alt='' width={200} height={8} />
                                </div>
                                <div className='w-[230px] group-hover:text-white text-black text-center text-base'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                                </div>
                                <div className='mt-3 text-base font-medium group-hover:text-white'>Hiran Welagedara</div>
                                <div className='text-xs font-medium group-hover:text-white'>Co-Founder</div>
                            </div>
                        </div>
                    </div>
                    {/* Sliding col end */}

                    {/* Sliding col start */}
                    <div className='flex-col hidden gap-10 lg:flex'>
                        {/* Card */}
                        <div className='group bg-white rounded-lg h-[560px] w-[400px] overflow-hidden hover:bg-gradient-to-br flex justify-center flex-col items-center from-black relative mt-16'>
                            <div className='flex-col h-[320px] justify-center items-center flex'>

                                <div className='flex flex-row justify-center w-full'>
                                    <img src='images/Ashan.png' alt='' width={70} height={70} className='border-2 border-black rounded-full group-hover:border-white' />
                                </div>
                                <div className='mt-4 text-xl font-bold text-center group-hover:text-white'>
                                Wholeheartedly Recommend!
                                </div>
                                <div>
                                    <img src='images/rating.svg' alt='' width={200} height={8} />
                                </div>
                                <div className='w-[320px] group-hover:text-white text-black text-center text-sm'>
                                As Rotaframe Technology Pvt Ltd has outstanding service and proficiency in providing high-caliber software solutions, I heartily suggest collaborating with them. They are a great option for anyone looking for dependable and innovative software development services. Dedication to perfection, which is obvious in every part of their work. Rotaframe Technology Pvt Ltd often exhibits a thorough comprehension of the needs of the client and provides creative solutions. I wholeheartedly recommend Rotaframe Technology Pvt Ltd as the best provider in the sector.
                                </div>
                                <div className='mt-3 text-base font-semibold group-hover:text-white'>Ashan Withana</div>
                                <div className='text-xs font-light group-hover:text-white'>Software Engineer at Saberion</div>
                            </div>
                        </div>

                        {/* Card */}
                        <div className='group bg-white rounded-lg h-[430px] w-[400px] overflow-hidden hover:bg-gradient-to-br flex justify-center flex-col items-center from-black relative'>
                            <div className='flex-col h-[320px] justify-center items-center flex'>

                                <div className='flex flex-row justify-center w-full'>
                                    <img src='images/HiranBehance.png' alt='' width={70} height={70} className='border-2 border-black rounded-full group-hover:border-white' />
                                </div>
                                <div className='mt-2 text-xl font-semibold text-center group-hover:text-white'>
                                    Superb!
                                </div>
                                <div>
                                    <img src='images/rating.svg' alt='' width={200} height={8} />
                                </div>
                                <div className='w-[230px] group-hover:text-white text-black text-center text-base'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                                </div>
                                <div className='mt-3 text-base font-medium group-hover:text-white'>Hiran Welagedara</div>
                                <div className='text-xs font-medium group-hover:text-white'>Co-Founder</div>
                            </div>
                        </div>
                    </div>
                    {/* Sliding col end */}


                    
                </div>

              


            </div>

            <div></div>

            <div style={{zIndex:22}} className='absolute mt-[690px] md:mt-[880px]'>
                <button className='py-2 text-lg font-semibold text-white bg-black border-2 border-white rounded-lg shadow-2xl px-7 hover:bg-white hover:border-white hover:text-black'>Read more</button>
            </div>

        </div>
    );
}

export default Testimonials;
