import React from "react";
import "./Service.css";
import Service1 from "./Service1";
import Marquee from "react-fast-marquee";

function Service() {


  return (
    <section id="service" className="service">
      <div className="md:mt-[320px] md:h-[850px] mt-20">
        <div style={{ position: "absolute", zIndex: 1 }} className="flex flex-row justify-center w-full h-full mt-20">
          <div className="flex-col items-center justify-center hidden w-full h-full gap-10 md:flex">
            <Marquee speed={15} gradient gradientColor="black">
              <div className="w-full h-full py-4">
                <div className="flex-row hidden w-full h-1/2 lg:flex">
                  <img src="images/medium.png" alt="" width={200} height={200} className="ml-[200px] mb-[50px] opacity-50" />
                  <img src="images/large.png" alt="" width={250} height={250} className="ml-[400px] opacity-50" />
                </div>
                <div className="flex flex-row lg:hidden">
                  <img src="images/xlarge.png" alt="" width={200} height={200} className="ml-[200px] mb-[50px] opacity-50" />
                  <img src="images/small.png" alt="" width={300} height={300} className="ml-[400px] opacity-50" />

                </div>
              </div>
            </Marquee>
            <Marquee speed={35} gradient gradientColor="black">
              <div className="w-full h-full">
                <div className="flex-row hidden w-full h-1/2 lg:flex">
                  <img src="images/Layer1.svg" alt="" width={300} height={300} className="opacity-50" />
                  <img src="images/Layer4.svg" alt="" width={100} height={100} className="ml-[300px] opacity-50" />
                </div>
                <div className="flex flex-row lg:hidden">
                  <img src="images/Layer1.svg" alt="" width={300} height={300} className="opacity-50" />
                  <img src="images/Layer4.svg" alt="" width={150} height={150} className="ml-[300px] opacity-50" />
                </div>
              </div>
            </Marquee>
          </div>


        </div>
        <div className="flex flex-row justify-center w-full " style={{ display: "flex", zIndex: 99 }} >
          {/* Start Content */}
          <section className="service1 snap-start" style={{ flex: "0 0 auto", zIndex: 40 }}>
            <Service1 />
          </section>

        </div>
      </div>
    </section>
  );
}

export default Service;
