import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { RiSendPlaneFill } from "react-icons/ri";


const ContactUsForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_5qe72vj', 'template_r1108tv', form.current, {
        publicKey: 'CveuwAuQ3YH0yrgvF',
      })
      .then(
        (result) => {
          console.log(result.text);
          alert('Message Sent, We will get back to you shortly', result.text);
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  return (
    <section id='contactUs'>
      <div className='flex flex-col items-center w-full h-full px-10'>
        <div className="font-dela-gothic-one sm:text-[50px] lg:text-[76px] xl:text-[90px] text-[35px] md:text-[35px] text-center px-[50px]">
          <span className="gradient-text">Let's Work Together</span>
        </div>
        <div className="px-[50px] mt-4 text-sm font-light text-center text-white sm:text-lg lg:text-xl xl:text-2xl sm:px-0">
          Reach out to us today to discuss your project ideas, goals, and challenges.
        </div>
        <div className="text-sm font-light text-center text-white sm:text-lg lg:text-xl xl:text-2xl px-[50px]">
          Let's leverage our expertise and experience to create impactful solutions tailored to your needs.
        </div>
        {/* Buttons */}
        <div className="flex flex-col items-center justify-center gap-5 mt-10 sm:gap-0 sm:flex-row">

          <div className="flex flex-col md:flex-row gap-12 md:gap-20 md:mt-[90px] mt-6">
            <div className="flex flex-row items-center justify-center">
              <div className="pr-6">
                <img src="images/Email.svg" alt="" height={45} width={45} className='hidden md:flex' />
                <img src="images/Email.svg" alt="" height={25} width={25} className='md:hidden' />
              </div>
              <div className="flex flex-col">
                <div className="text-sm text-white md:text-lg">Email</div>
                <div className="text-base font-bold text-white md:text-2xl md:font-normal">rotaframetechnology@gmail.com</div>
              </div>
            </div>

            <div className="flex flex-row items-center">
              <div className="pr-6">
                <img src="images/Calling.svg" alt="" height={45} width={45} className='hidden md:flex' />
                <img src="images/Calling.svg" alt="" height={30} width={30} className='md:hidden' />
              </div>
              <div className="flex flex-col">
                <div className="text-sm text-white md:text-lg">Phone</div>
                <div className="text-base font-bold text-white md:text-2xl md:font-normal">+94 (74) 309 4183</div>
              </div>
            </div>

          </div>
        </div>

        <form ref={form} onSubmit={sendEmail} className="px-8 pt-6 pb-8 mb-4 bg-black rounded shadow-md">
          <div className="mb-4 mt-[50px] flex flex-col items-center w-full">
            {/* First Raw */}
            <div className='flex flex-col md:flex-row w-screen  md:w-[1100px] gap-10 px-10'>
              <input
                className="w-full text-sm md:text-base py-[15px] px-3 leading-tight text-black bg-gray-200 shadow appearance-none md:py-3 md:w-1/2 rounded-xl focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                placeholder="Your name"
                name="name"

              />

              <input
                className="w-full text-sm md:text-base md:w-1/2 px-3 py-2 leading-tight text-black bg-gray-200 border h-[50px] shadow appearance-none rounded-xl focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                placeholder="Your email"
                name="email"

              />
            </div>

            <div className='flex flex-col items-center w-full px-10'>
              <div className='flex flex-col w-full md:w-[1020px] mt-10 h-[170px] bg-gray-200 rounded-2xl'>
                <div className='flex flex-row w-full md:h-5/6'>
                  <textarea
                    className="w-32 md:w-[1020px] px-3 py-2 leading-tight bg-gray-200 h-[120px] appearance-none rounded-xl focus:outline-none focus:shadow-outline"
                    id="message"
                    placeholder="Message"
                    name="message"
                  ></textarea> {/* Added closing tag */}
                </div>
                <div className='flex flex-row justify-end w-full'>
                  <div className='w-[160px] h-[50px] border-l-0 border-white  border-t-0 border-b-0'>
                    <button className='flex-row items-center justify-center w-full h-full text-lg font-semibold text-white bg-black border-4 border-gray-200 rounded-xl hover:bg-white hover:text-black hover:border-black' type="submit">
                      <div className='flex flex-row items-center justify-center'>
                        <div>Send</div>
                        <div className='pl-2'><RiSendPlaneFill /></div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactUsForm;
