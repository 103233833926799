import React, { useState, useEffect } from 'react'

function ProjectsCount() {
    const [projectDoneCount, setprojectDoneCount] = useState(0);
    const [customersCount, setCustomersCount] = useState(0);
    const [productsCount, setProductsCount] = useState(0);
    const [isCountdownVisible, setIsCountdownVisible] = useState(false); // State to track countdown visibility

    useEffect(() => {
        const startSlowCountdownAnimation = () => {
            const projectDone = 12;
            const customers = 10;
            const products = 2;

            const projectDoneIncrement = projectDone / 100;
            const customersIncrement = customers / 100;
            const productsIncrement = products / 100;

            let i = 0;
            const interval = setInterval(() => {
                if (i <= 100) {
                    setprojectDoneCount(Math.floor(i * projectDoneIncrement));
                    setCustomersCount(Math.floor(i * customersIncrement));
                    setProductsCount(Math.floor(i * productsIncrement));
                    i++;
                } else {
                    clearInterval(interval);
                }
            }, 10);
        };

        // Trigger animation when countdown section is visible
        if (isCountdownVisible) {
            startSlowCountdownAnimation();
        }
    }, [isCountdownVisible]);
  return (
    <div className='flex flex-row justify-center '>
        <div className='hidden md:flex flex-col md:flex-row md:w-[1440px] h-[600px] md:h-[150px] bg-black md:px-20'>
              <div className='flex w-full h-full mx-0 bg-white lg:rounded-xl' style={{
                  background: 'radial-gradient(circle, #000000 8%, #808080 70%, #ffffff 100%)',
                  backgroundSize: '120% 120%', // Increase the size to spread the gradient more
                  backgroundPosition: 'center'
              }}
                  ref={(node) => {
                      // Detect when the countdown section is in viewport
                      if (node) {
                          const observer = new IntersectionObserver(
                              (entries) => {
                                  entries.forEach((entry) => {
                                      if (entry.isIntersecting) {
                                          setIsCountdownVisible(true);
                                      }
                                  });
                              },
                              { threshold: 0.5 } // Adjust threshold as needed
                          );
                          observer.observe(node);
                      }
                  }}
              >
                <div className='flex flex-col items-center justify-between w-full h-full py-10 md:px-2 lg:px-16 md:flex-row'>
                    <div className='flex flex-col items-center justify-center'>
                          <div className='text-5xl font-semibold text-white md:text-4xl lg:text-6xl'>{projectDoneCount}+</div>
                        <div className='mt-2 text-xl font-semibold text-white md:text-lg 2xl:text-2xl'>Projects Done</div>
                    </div>
                      <div className='flex flex-col items-center justify-center'>
                          <div className='text-5xl font-semibold text-white md:text-4xl lg:text-6xl'>{customersCount}+</div>
                          <div className='mt-2 text-xl font-semibold text-white md:text-lg 2xl:text-2xl'>Customers</div>
                      </div>
                      <div className='flex flex-col items-center justify-center'>
                          <div className='text-5xl font-semibold text-white md:text-4xl lg:text-6xl'>24/7</div>
                          <div className='mt-2 text-xl font-semibold text-white md:text-lg 2xl:text-2xl'>Availability</div>
                      </div>
                      <div className='flex flex-col items-center justify-center'>
                          <div className='text-5xl font-semibold text-white md:text-4xl lg:text-6xl'>{productsCount}+</div>
                          <div className='mt-2 text-xl font-semibold text-white md:text-lg 2xl:text-2xl'>Products</div>
                      </div>
                </div>
              </div>


        </div>


          <div className='md:hidden flex flex-col lg:flex-row w-screen h-[600px] sm:h-[700px] lg:h-[150px] bg-black lg:px-20'>
              <div className='flex w-full h-full mx-0 bg-black lg:rounded-xl'
                  ref={(node) => {
                      // Detect when the countdown section is in viewport
                      if (node) {
                          const observer = new IntersectionObserver(
                              (entries) => {
                                  entries.forEach((entry) => {
                                      if (entry.isIntersecting) {
                                          setIsCountdownVisible(true);
                                      }
                                  });
                              },
                              { threshold: 0.5 } // Adjust threshold as needed
                          );
                          observer.observe(node);
                      }
                  }}
              >
                  <div className='flex flex-col items-center justify-between w-full h-full px-16 py-10 lg:flex-row'>
                      <div className='flex flex-col items-center justify-center'>
                          <div className='text-5xl font-semibold text-white sm:text-6xl lg:text-6xl'>{projectDoneCount}+</div>
                          <div className='mt-2 text-xl font-semibold text-white'>Projects Done</div>
                      </div>
                      <div className='flex flex-col items-center justify-center'>
                          <div className='text-5xl font-semibold text-white sm:text-6xl lg:text-6xl'>{customersCount}+</div>
                          <div className='mt-2 text-xl font-semibold text-white'>Customers</div>
                      </div>
                      <div className='flex flex-col items-center justify-center'>
                          <div className='text-5xl font-semibold text-white sm:text-6xl lg:text-6xl'>24/7</div>
                          <div className='mt-2 text-xl font-semibold text-white'>Availability</div>
                      </div>
                      <div className='flex flex-col items-center justify-center'>
                          <div className='text-5xl font-semibold text-white sm:text-6xl lg:text-6xl'>{productsCount}+</div>
                          <div className='text-xl font-semibold text-white text-whitemt-2'>Products</div>
                      </div>
                  </div>
              </div>


          </div>
    </div>
  )
}

export default ProjectsCount