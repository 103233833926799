import React from 'react'

function Service1() {
  return (

    <div className="flex flex-col justify-center md:w-[1440px] md:h-[850PX] px-[150px]">

      <div className="flex flex-row justify-center w-full font-dela-gothic-one text-center md:mb-[80px] gradient-text sm:text-[50px] lg:text-[76px] xl:text-[90px] text-[35px]">Our Services</div>
      <div className='flex-col hidden w-full md:flex'>
        {/* Section */}
        <div className='flex flex-row justify-start w-full'>
          <div className='flex flex-col'>
            <div className="flex flex-row w-full text-5xl font-bold text-center gradient-text">Web Design & Development</div>
            <div className='w-3/4 mt-5 text-xl text-white pr-28'>Unleash the potential of your online identity with Rotaframe Technology's bespoke web design and development services. Our expert team crafts captivating websites and robust web applications that leave a lasting impression on your visitors. From visually stunning interfaces to seamless user experiences, we tailor every aspect to align with your brand and engage your audience effectively.
            </div>
            <div className='flex flex-row items-center w-[1000px] '>
              <div className='w-3/4 h-[1px] bg-white mt-5'></div>
              <div className='w-3 h-3 mt-5 bg-white rounded-full'></div>
            </div>
          </div>
        </div>

        {/* Section */}
        <div className='flex flex-row justify-end w-full mt-10'>
          <div className='flex flex-col md:w-[1000px]'>
            <div className='flex flex-row justify-end w-full'>
              <div className='mt-10 text-5xl font-bold text-white'>Mobile Application Development</div>
            </div>
            <div className='flex flex-row justify-end w-full'>
              <div className='pl-[280px] mt-5 text-xl text-right text-white'>Stay at the forefront of the mobile-first era with Rotaframe Technology's stellar mobile app development services. Whether it's iOS or Android, our team specializes in creating intuitive and feature-rich applications. Elevate your user experience, resonate with your audience, and make a lasting impact in the digital realm.
              </div>
            </div>

            <div className='flex flex-row justify-end md:w-[1000px]'>
              <div className='flex flex-row items-center justify-end w-full'>
                <div className='w-3 h-3 mt-5 bg-white rounded-full'></div>
                <div className='w-3/4 bg-white h-[1px] mt-5'></div>

              </div>
            </div>
          </div>
        </div>

        {/* Section */}
        <div className='flex flex-row justify-start w-full mt-24'>
          <div className='flex flex-col'>
            <div className="flex flex-row w-full text-5xl font-bold text-center gradient-text">IT Consulting
            </div>
            <div className='w-3/4 mt-5 text-xl text-white pr-28'>Navigate the intricate landscape of technology with confidence, guided by Rotaframe Technology's seasoned IT consultants. Gain strategic insights that empower you to make informed decisions, align your IT strategy with your business goals, and stay ahead of the curve in a rapidly evolving digital environment.</div>
            <div className='flex flex-row items-center w-[1000px] '>
              <div className='w-3/4 h-[1px] bg-white mt-5'></div>
              <div className='w-3 h-3 mt-5 bg-white rounded-full'></div>
            </div>
          </div>
        </div>

      </div>


      {/* Mobile Responsive */}
      <div className='flex flex-col w-screen px-10 mt-10 md:hidden'>
        <div className='flex flex-col text-white'>
          <div className='flex flex-row w-full text-3xl font-bold text-center text-white'>
            Web Design & Development
          </div>
          <div className='flex flex-row justify-center w-full pt-5 text-base text-center text-white'>
             Unleash the potential of your online identity with Rotaframe Technology's bespoke web design and development services. Our expert team crafts captivating websites and robust web applications that leave a lasting impression on your visitors. From visually stunning interfaces to seamless user experiences, we tailor every aspect to align with your brand and engage your audience effectively.
          </div>
          <div className='flex flex-row w-full h-[1px] bg-white mt-5'></div>
        </div>

        <div className='flex flex-col mt-10 text-white'>
          <div className='flex flex-row w-full text-3xl font-bold text-center text-white'>
            Mobile Application Development
          </div>
          <div className='flex flex-row justify-center w-full pt-5 text-base text-center text-white'>
            Stay at the forefront of the mobile-first era with Rotaframe Technology's stellar mobile app development services. Whether it's iOS or Android, our team specializes in creating intuitive and feature-rich applications. Elevate your user experience, resonate with your audience, and make a lasting impact in the digital realm.
          </div>
          <div className='flex flex-row w-full h-[1px] bg-white mt-5'></div>
        </div>

        <div className='flex flex-col mt-10 text-white'>
          <div className='flex flex-row justify-center w-full text-3xl font-bold text-center text-white'>
            IT Consulting
          </div>
          <div className='flex flex-row justify-center w-full pt-5 text-base text-center text-white'>
            Navigate the intricate landscape of technology with confidence, guided by Rotaframe Technology's seasoned IT consultants. Gain strategic insights that empower you to make informed decisions, align your IT strategy with your business goals, and stay ahead of the curve in a rapidly evolving digital environment.
          </div>
          <div className='flex flex-row w-full h-[1px] bg-white mt-5'></div>
        </div>
      </div>





    </div>
  );
}

export default Service1