import React from 'react';
import Navbar from './Navbar';
import frameImage from '../assets/Frame2.jpg'; // Import the frame.jpg image
import Service from './Service';

const Home = () => {
 

  return (
<section id='home'>
    <div className='flex flex-row justify-center'>

    <div className="flex flex-col items-center sm:h-[600px] justify-center h-[600px] md:h-[500px] pt-60 md:w-[1440px]">
      <div className="flex flex-col items-center px-5 mx-auto text-center content">
      <h1 className=" font-dela-gothic-one sm:text-[30px] md:text-[40px] lg:text-[44px] font-black  gradient-text text-[20px] md:mt-[200px]">
      Empowering Your Digital Transformation with
        </h1>
        <h1 className="font-dela-gothic-one sm:text-[50px] md:text-[60px] lg:text-[100px] font-black gradient-text text-[50px]">
        Innovative Solutions!
        </h1>

        <div className="container flex items-center justify-center px-2">
          <p className="w-full mt-10 text-sm text-gray-100 sm:mt-50 sm:text-lg lg:text-2xl lg:mx-20 md:mx-12 xl:mx-24 2xl:text-2xl">
          Welcome to Rotaframe Technology, where innovation meets functionality to redefine your online presence. Transcend boundaries to transform your digital aspirations into reality. As a renowned software development and IT consulting company, we take pride in delivering cutting-edge solutions that propel your business forward in the ever-evolving digital landscape.
          </p>
        </div>

        <div className='flex flex-col items-center justify-center md:flex-row'>
            <button className="mt-16 md:mr-5 h-10 font-semibold text-lg text-black bg-white rounded-[12px] w-44 sm:w-48 sm:h-12 sm:text-base hover:bg-black hover:border-2 hover:border-white hover:text-white xl:text-xl xl:w-[160px] xl:h-14">
                <a href="#contactUs" className="duration-200">
              Learn more</a>
            </button>
            <button className="mt-6 md:mt-16 h-10 font-semibold text-lg text-white rounded-[12px] w-44 sm:w-48 sm:h-12 sm:text-base hover:bg-white border-2 border-white hover:text-black xl:text-xl xl:w-52 xl:h-14">
                <a href="#contactUs" className="duration-200">
              Request a Demo</a>
            </button>
        </div>
        


      </div>
    </div>
  </div>
  </section>
  );
};

export default Home;
