import React from 'react'

function Portfolio() {
    return (
        <section id='portfolio'>
            <div className='flex flex-col items-center md:mt-[380px] mt-[150px]'>
                {/* Page Title */}

                <div className="flex flex-row justify-center w-full font-dela-gothic-one text-center mb-8 gradient-text sm:text-[50px] lg:text-[76px] xl:text-[90px] text-[35px]">
                    <span className="gradient-text">Project Portfolio</span>
                </div>

                {/* Projects Line 1 */}
                <div className='flex flex-col w-full md:w-[1440px] mt:pt-16 md:mt-0'>
                    <div className='flex flex-col items-center mb-5 md:flex-row md:mr-16 lg:mr-0 '>

                        {/*First  Project */}
                        <div className='group sm:w-11/12 mx-5 bg-white rounded-lg h-[250px] sm:h-[300px] overflow-hidden hover:bg-gradient-to-br from-black relative md:mt-0 flex flex-col  justify-center md:ml-20 md:mx-0'>
                            {/* project Image */}
                            <div className='absolute right-0 transform -translate-y-1/2 top-1/2 '>
                                <img className='transition-transform duration-300 transform scale-50 opacity-0 md:opacity-50 group-hover:scale-95 sm:scale-100 sm:group-hover:scale-110 group-hover:opacity-100 sm:flex' src='images/traneb.svg' alt='' width={120} height={120} />
                            </div>
                            <div className='flex-col pl-5 md:pl-4'>
                                {/* project Logo  */}
                                <div className='flex flex-row'>
                                    <div className='hidden sm:grid md:hidden'>
                                        <img src='images/tn.svg' alt='' width={100} height={100} className='group-hover:hidden' />
                                        <img src='images/tn2.svg' alt='' width={100} height={100} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden md:grid xl:hidden'>
                                        <img src='images/tn.svg' alt='' width={80} height={80} className='group-hover:hidden' />
                                        <img src='images/tn2.svg' alt='' width={80} height={80} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden xl:grid'>
                                        <img src='images/tn.svg' alt='' width={160} height={160} className='group-hover:hidden' />
                                        <img src='images/tn2.svg' alt='' width={160} height={160} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='pb-2 sm:hidden'>
                                        <img src='images/tn.svg' alt='' width={90} height={90} className='group-hover:hidden' />
                                        <img src='images/tn2.svg' alt='' width={90} height={90} className='hidden group-hover:grid' />
                                    </div>
                                </div>

                                {/* Project Description */}
                                <div className='sm:w-[400px] sm:pt-3 text-black group-hover:text-white sm:text-lg md:text-sm md:w-[200px] xl:w-[250px] xl:text-base text-sm pr-20 sm:pr-0'>
                                    This project aims to develop a user-friendly and visually engaging mobile application interface for seamless travel ticket booking. The interface incorporates...
                                </div>

                                {/* Social Media Icons */}
                                <div className='flex-row hidden pt-4 sm:flex md:hidden'>
                                    <a href='https://www.behance.net/gallery/186917209/Travel-Booking-Mobile-Application-UI'><img src='images/Git.svg' alt='' width={30} height={30} className='mr-1 group-hover:hidden' /></a>
                                    <a href='https://www.behance.net/gallery/186917209/Travel-Booking-Mobile-Application-UIe'><img src='images/Behance.svg' alt='' width={30} height={30} className='group-hover:hidden' /></a>
                                    <a href='https://www.behance.net/gallery/186917209/Travel-Booking-Mobile-Application-UI'><img src='images/Git1.svg' alt='' width={30} height={30} className='hidden mr-1 group-hover:grid' /></a>
                                    <a href='https://www.behance.net/gallery/186917209/Travel-Booking-Mobile-Application-UI'><img src='images/Behace1.svg' alt='' width={30} height={30} className='hidden group-hover:grid' /></a>
                                </div>
                                <div className='hidden md:flex'>
                                    <div className='flex flex-row pt-4'>
                                        <a href='https://www.behance.net/gallery/186917209/Travel-Booking-Mobile-Application-UI'><img src='images/Git.svg' alt='' width={30} height={30} className='mr-1 group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/gallery/186917209/Travel-Booking-Mobile-Application-UI'><img src='images/Behance.svg' alt='' width={30} height={30} className='group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/gallery/186917209/Travel-Booking-Mobile-Application-UI'><img src='images/Git1.svg' alt='' width={30} height={30} className='hidden mr-1 group-hover:grid' /></a>
                                        <a href='https://www.behance.net/gallery/186917209/Travel-Booking-Mobile-Application-UI'><img src='images/Behace1.svg' alt='' width={30} height={30} className='hidden group-hover:grid' /></a>
                                    </div>
                                </div>
                                <div className='sm:hidden'>
                                    <div className='flex flex-row pt-2'>
                                        <a href='https://www.behance.net/gallery/186917209/Travel-Booking-Mobile-Application-UI'><img src='images/Git.svg' alt='' width={25} height={25} className='mr-1 group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/gallery/186917209/Travel-Booking-Mobile-Application-UI'><img src='images/Behance.svg' alt='' width={25} height={25} className='group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/gallery/186917209/Travel-Booking-Mobile-Application-UI'><img src='images/Git1.svg' alt='' width={25} height={25} className='hidden mr-1 group-hover:grid' /></a>
                                        <a href='https://www.behance.net/gallery/186917209/Travel-Booking-Mobile-Application-UI'><img src='images/Behace1.svg' alt='' width={25} height={25} className='hidden group-hover:grid' /></a>
                                    </div>
                                </div>

                                {/* Explore More part */}
                                <div className='flex flex-row items-center pt-2 sm:pt-3'>
                                    <div className='mr-2 text-sm text-black sm:text-base group-hover:text-white lg:text-sm xl:text-base'><a href='https://www.behance.net/gallery/186917209/Travel-Booking-Mobile-Application-UI'>Explore more</a></div>
                                    <div className='hidden sm:flex md:hidden'>
                                        <img src='images/arrow.svg' alt='' width={20} height={20} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={20} height={20} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden md:flex xl:hidden'>
                                        <img src='images/arrow.svg' alt='' width={15} height={15} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={15} height={15} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden xl:flex'>
                                        <img src='images/arrow.svg' alt='' width={18} height={18} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={18} height={18} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='sm:hidden'>
                                        <img src='images/arrow.svg' alt='' width={15} height={15} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={15} height={15} className='hidden group-hover:grid' />
                                    </div>
                                </div>
                            </div>
                        </div>





                        {/* Second Project */}
                        <div className='group sm:w-11/12 mx-5 bg-white rounded-lg h-[250px] sm:h-[300px] overflow-hidden hover:bg-gradient-to-br mt-10 from-black relative md:mt-0 flex flex-col  justify-center'>
                            {/* project Image */}
                            <div className='absolute right-0 transform -translate-y-1/2 top-1/2 '>
                                <img className='transition-transform duration-300 transform scale-50 opacity-0 md:opacity-50 group-hover:scale-95 sm:scale-100 sm:group-hover:scale-110 group-hover:opacity-100' src='images/iNfo.svg' alt='' width={120} height={120} />
                            </div>
                            <div className='flex-col pl-5 md:pl-4'>
                                {/* project Logo  */}
                                <div className='flex flex-row'>
                                    <div className='hidden sm:flex md:hidden'>
                                        <img src='images/infologo.svg' alt='' width={100} height={100} className='group-hover:hidden' />
                                        <img src='images/infologo.svg' alt='' width={100} height={100} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden md:grid xl:hidden'>
                                        <img src='images/infologo.svg' alt='' width={80} height={80} className='group-hover:hidden' />
                                        <img src='images/infologo.svg' alt='' width={80} height={80} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden xl:grid'>
                                        <img src='images/infologo.svg' alt='' width={48} height={48} className='group-hover:hidden' />
                                        <img src='images/infologo.svg' alt='' width={48} height={48} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='pb-2 sm:hidden'>
                                        <img src='images/infologo.svg' alt='' width={90} height={90} className='group-hover:hidden' />
                                        <img src='images/infologo.svg' alt='' width={90} height={90} className='hidden group-hover:grid' />
                                    </div>
                                </div>

                                {/* Project Description */}
                                <div className='sm:w-[400px] sm:pt-3 text-black group-hover:text-white sm:text-lg md:text-sm md:w-[200px] xl:text-base xl:w-[250px] text-sm pr-20 sm:pr-0'>
                                    This project showcases a mobile application designed & development to streamline campus life and empower students with easy access to essential...
                                </div>

                                {/* Social Media Icons */}
                                <div className='flex-row hidden pt-4 sm:flex md:hidden'>
                                    <a href='https://www.behance.net/gallery/182259823/iNfo-Mobile-Application-Prototype'><img src='images/Git.svg' alt='' width={30} height={30} className='mr-1 group-hover:hidden' /></a>
                                    <a href='https://www.behance.net/rotaframe'><img src='images/Behance.svg' alt='' width={30} height={30} className='group-hover:hidden' /></a>
                                    <a href='https://www.behance.net/gallery/182259823/iNfo-Mobile-Application-Prototype'><img src='images/Git1.svg' alt='' width={30} height={30} className='hidden mr-1 group-hover:grid' /></a>
                                    <a href='https://www.behance.net/rotaframe'><img src='images/Behace1.svg' alt='' width={30} height={30} className='hidden group-hover:grid' /></a>
                                </div>
                                <div className='hidden md:flex'>
                                    <div className='flex flex-row pt-4'>
                                        <a href='https://www.behance.net/gallery/182259823/iNfo-Mobile-Application-Prototype'> <img src='images/Git.svg' alt='' width={30} height={30} className='mr-1 group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/rotaframe'><img src='images/Behance.svg' alt='' width={30} height={30} className='group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/gallery/182259823/iNfo-Mobile-Application-Prototype'><img src='images/Git1.svg' alt='' width={30} height={30} className='hidden mr-1 group-hover:grid' /></a>
                                        <a href='https://www.behance.net/rotaframe'><img src='images/Behace1.svg' alt='' width={30} height={30} className='hidden group-hover:grid' /></a>
                                    </div>
                                </div>
                                <div className='sm:hidden'>
                                    <div className='flex flex-row pt-2'>
                                        <a href='https://www.behance.net/gallery/182259823/iNfo-Mobile-Application-Prototype'><img src='images/Git.svg' alt='' width={25} height={25} className='mr-1 group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/rotaframe'><img src='images/Behance.svg' alt='' width={25} height={25} className='group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/gallery/182259823/iNfo-Mobile-Application-Prototype'><img src='images/Git1.svg' alt='' width={25} height={25} className='hidden mr-1 group-hover:grid' /></a>
                                        <a href='https://www.behance.net/rotaframe'><img src='images/Behace1.svg' alt='' width={25} height={25} className='hidden group-hover:grid' /></a>
                                    </div>
                                </div>

                                {/* Explore More part */}
                                <div className='flex flex-row items-center pt-2 sm:pt-3'>
                                    <div className='mr-2 text-sm text-black sm:text-base group-hover:text-white lg:text-sm xl:text-base'><a href='https://www.behance.net/gallery/182259823/iNfo-Mobile-Application-Prototype'>Explore more</a></div>
                                    <div className='hidden sm:flex md:hidden'>
                                        <img src='images/arrow.svg' alt='' width={20} height={20} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={20} height={20} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden md:flex xl:hidden'>
                                        <img src='images/arrow.svg' alt='' width={15} height={15} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={15} height={15} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden xl:flex'>
                                        <img src='images/arrow.svg' alt='' width={18} height={18} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={18} height={18} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='sm:hidden'>
                                        <img src='images/arrow.svg' alt='' width={15} height={15} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={15} height={15} className='hidden group-hover:grid' />
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* Third Project */}
                        <div className='md:hidden lg:flex group sm:w-11/12 mx-5 bg-white rounded-lg sm:h-[300px] h-[250px] overflow-hidden hover:bg-gradient-to-br mt-10 from-black relative md:mt-0 flex flex-col  justify-center md:mr-20 md:mx-0'>
                            {/* project Image */}
                            <div className='absolute right-0 transform -translate-y-1/2 top-1/2 '>
                                <img className='transition-transform duration-300 transform scale-50 opacity-0 md:opacity-50 group-hover:scale-95 sm:scale-100 sm:group-hover:scale-110 group-hover:opacity-100' src='images/rp.svg' alt='' width={120} height={140} />
                            </div>
                            <div className='flex-col pl-5 md:pl-4'>
                                {/* project Logo  */}
                                <div className='flex flex-row'>
                                    <div className='hidden sm:flex md:hidden'>
                                        <img src='images/rplogo1.svg' alt='' width={100} height={100} className='group-hover:hidden' />
                                        <img src='images/rplogo.svg' alt='' width={100} height={100} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden md:grid xl:hidden'>
                                        <img src='images/rplogo1.svg' alt='' width={80} height={80} className='group-hover:hidden' />
                                        <img src='images/rplogo.svg' alt='' width={80} height={80} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden xl:grid'>
                                        <img src='images/rplogo1.svg' alt='' width={120} height={120} className='group-hover:hidden' />
                                        <img src='images/rplogo.svg' alt='' width={120} height={120} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='pb-2 sm:hidden'>
                                        <img src='images/rplogo1.svg' alt='' width={90} height={90} className='group-hover:hidden' />
                                        <img src='images/rplogo.svg' alt='' width={90} height={90} className='hidden group-hover:grid' />
                                    </div>
                                </div>

                                {/* Project Description */}
                                <div className='sm:w-[400px]  sm:pt-3 text-black group-hover:text-white sm:text-lg md:text-sm md:w-[200px] xl:text-base xl:w-[250px] text-sm pr-20 sm:pr-0'>
                                    Spearheaded the design and development of a cutting-edge mobile application tailored to streamline the process of ordering goods for restaurants directly...
                                </div>

                                {/* Social Media Icons */}
                                <div className='flex-row hidden pt-4 sm:flex md:hidden'>
                                    <a href='https://www.behance.net/gallery/189119713/Onboarding-Pages-for-Online-Food-Ordering-Platform'><img src='images/Git.svg' alt='' width={30} height={30} className='mr-1 group-hover:hidden' /></a>
                                    <a href='https://www.behance.net/rotaframe'><img src='images/Behance.svg' alt='' width={30} height={30} className='group-hover:hidden' /></a>
                                    <a href='https://www.behance.net/gallery/189119713/Onboarding-Pages-for-Online-Food-Ordering-Platform'><img src='images/Git1.svg' alt='' width={30} height={30} className='hidden mr-1 group-hover:grid' /></a>
                                    <a href='https://www.behance.net/rotaframe'><img src='images/Behace1.svg' alt='' width={30} height={30} className='hidden group-hover:grid' /></a>
                                </div>
                                <div className='hidden md:flex'>
                                    <div className='flex flex-row pt-4'>
                                        <a href='https://www.behance.net/gallery/189119713/Onboarding-Pages-for-Online-Food-Ordering-Platform'><img src='images/Git.svg' alt='' width={30} height={30} className='mr-1 group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/rotaframe'><img src='images/Behance.svg' alt='' width={30} height={30} className='group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/gallery/189119713/Onboarding-Pages-for-Online-Food-Ordering-Platform'><img src='images/Git1.svg' alt='' width={30} height={30} className='hidden mr-1 group-hover:grid' /></a>
                                        <a href='https://www.behance.net/rotaframe'><img src='images/Behace1.svg' alt='' width={30} height={30} className='hidden group-hover:grid' /></a>
                                    </div>
                                </div>
                                <div className='sm:hidden'>
                                    <div className='flex flex-row pt-2'>
                                        <a href='https://www.behance.net/gallery/189119713/Onboarding-Pages-for-Online-Food-Ordering-Platform'><img src='images/Git.svg' alt='' width={25} height={25} className='mr-1 group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/rotaframe'><img src='images/Behance.svg' alt='' width={25} height={25} className='group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/gallery/189119713/Onboarding-Pages-for-Online-Food-Ordering-Platform'><img src='images/Git1.svg' alt='' width={25} height={25} className='hidden mr-1 group-hover:grid' /></a>
                                        <a href='https://www.behance.net/rotaframe'><img src='images/Behace1.svg' alt='' width={25} height={25} className='hidden group-hover:grid' /></a>
                                    </div>
                                </div>

                                {/* Explore More part */}
                                <div className='flex flex-row items-center pt-2 sm:pt-3'>
                                    <div className='mr-2 text-sm text-black sm:text-base group-hover:text-white md:text-sm xl:text-base'><a href='https://www.behance.net/gallery/189119713/Onboarding-Pages-for-Online-Food-Ordering-Platform'>Explore more</a></div>
                                    <div className='hidden sm:flex md:hidden'>
                                        <img src='images/arrow.svg' alt='' width={20} height={20} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={20} height={20} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden md:flex xl:hidden'>
                                        <img src='images/arrow.svg' alt='' width={15} height={15} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={15} height={15} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden xl:flex'>
                                        <img src='images/arrow.svg' alt='' width={18} height={18} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={18} height={18} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='sm:hidden'>
                                        <img src='images/arrow.svg' alt='' width={15} height={15} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={15} height={15} className='hidden group-hover:grid' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Projects Line 2 */}
                <div className='flex flex-col md:w-[1440px] md:pr-5'>
                    <div className='flex flex-col items-center mt-5 mb-5 sm:mt-0 md:flex-row h-1/2'>

                        {/*First  Project */}
                        <div className='hidden group sm:w-11/12 mx-5 bg-white rounded-lg sm:h-[300px] h-[250px] overflow-hidden hover:bg-gradient-to-br from-black relative md:w-11/12 lg:w-6/12 md:mx-3 md:ml-20 md:flex flex-col justify-center'>
                            {/* project Image */}
                            <div className='absolute right-0 transform -translate-y-1/2 top-1/2 '>
                                <img className='transition-transform duration-300 transform scale-50 opacity-50 group-hover:scale-95 sm:scale-100 sm:group-hover:scale-110 group-hover:opacity-100' src='images/cn.svg' alt='' width={340} height={340} />
                            </div>
                            <div className='flex-col pl-5'>
                                {/* project Logo  */}
                                <div className='flex flex-row'>
                                    <div className='hidden sm:flex md:hidden'>
                                        <img src='images/cnlogo.svg' alt='' width={100} height={100} className='group-hover:hidden' />
                                        <img src='images/cnlogo.svg' alt='' width={100} height={100} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden md:flex xl:hidden'>
                                        <img src='images/cnlogo.svg' alt='' width={80} height={80} className='group-hover:hidden' />
                                        <img src='images/cnlogo.svg' alt='' width={80} height={80} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden xl:flex'>
                                        <img src='images/cnlogo.svg' alt='' width={120} height={120} className='group-hover:hidden' />
                                        <img src='images/cnlogo.svg' alt='' width={120} height={120} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='sm:hidden'>
                                        <img src='images/cnlogo.svg' alt='' width={90} height={90} className='group-hover:hidden' />
                                        <img src='images/cnlogo.svg' alt='' width={90} height={90} className='hidden group-hover:grid' />
                                    </div>
                                </div>

                                {/* Project Description */}
                                <div className='sm:w-[400px] sm:pt-3 text-black group-hover:text-white sm:text-lg md:text-sm md:w-[200px] xl:text-base xl:w-[300px] text-sm pr-20 sm:pr-0 mt-2'>
                                    Design a user-friendly and engaging website UI for a vibrant student circle that showcases its activities, fosters community engagement, and provides easy access to information...
                                </div>

                                {/* Social Media Icons */}
                                <div className='flex-row hidden pt-4 sm:flex md:hidden'>
                                    <a href='https://www.behance.net/gallery/182774453/Web-Design-for-Student-Circle'><img src='images/Git.svg' alt='' width={30} height={30} className='mr-1 group-hover:hidden' /></a>
                                    <a href='https://www.behance.net/rotaframe'><img src='images/Behance.svg' alt='' width={30} height={30} className='group-hover:hidden' /></a>
                                    <a href='https://www.behance.net/gallery/182774453/Web-Design-for-Student-Circle'><img src='images/Git1.svg' alt='' width={30} height={30} className='hidden mr-1 group-hover:grid' /></a>
                                    <a href='https://www.behance.net/rotaframe'><img src='images/Behace1.svg' alt='' width={30} height={30} className='hidden group-hover:grid' /></a>
                                </div>

                                <div className='hidden md:flex'>
                                    <div className='flex flex-row pt-4'>
                                        <a href='https://www.behance.net/gallery/182774453/Web-Design-for-Student-Circle'><img src='images/Git.svg' alt='' width={30} height={30} className='mr-1 group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/rotaframe'><img src='images/Behance.svg' alt='' width={30} height={30} className='group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/gallery/182774453/Web-Design-for-Student-Circle'><img src='images/Git1.svg' alt='' width={30} height={30} className='hidden mr-1 group-hover:grid' /></a>
                                        <a href='https://www.behance.net/rotaframe'><img src='images/Behace1.svg' alt='' width={30} height={30} className='hidden group-hover:grid' /></a>
                                    </div>
                                </div>
                                <div className='sm:hidden'>
                                    <div className='flex flex-row pt-2'>
                                        <a href='https://www.behance.net/gallery/182774453/Web-Design-for-Student-Circle'><img src='images/Git.svg' alt='' width={25} height={25} className='mr-1 group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/rotaframe'><img src='images/Behance.svg' alt='' width={25} height={25} className='group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/gallery/182774453/Web-Design-for-Student-Circle'><img src='images/Git1.svg' alt='' width={25} height={25} className='hidden mr-1 group-hover:grid' /></a>
                                        <a href='https://www.behance.net/rotaframe'><img src='images/Behace1.svg' alt='' width={25} height={25} className='hidden group-hover:grid' /></a>
                                    </div>
                                </div>

                                {/* Explore More part */}
                                <div className='flex flex-row items-center pt-2 sm:pt-3'>
                                    <div className='mr-2 text-sm text-black sm:text-base lg:text-sm group-hover:text-white xl:text-base'><a href='https://www.behance.net/gallery/182774453/Web-Design-for-Student-Circle'>Explore more</a></div>
                                    <div className='hidden sm:flex md:hidden'>
                                        <img src='images/arrow.svg' alt='' width={20} height={20} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={20} height={20} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden md:flex xl:hidden'>
                                        <img src='images/arrow.svg' alt='' width={15} height={15} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={15} height={15} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden xl:flex'>
                                        <img src='images/arrow.svg' alt='' width={18} height={18} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={18} height={18} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='sm:hidden'>
                                        <img src='images/arrow.svg' alt='' width={15} height={15} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={15} height={15} className='hidden group-hover:grid' />
                                    </div>
                                </div>
                            </div>
                        </div>





                        {/* Second Project */}
                        <div className='hidden group sm:w-11/12 mx-5 bg-white rounded-lg sm:h-[300px] h-[250px] overflow-hidden hover:bg-gradient-to-br from-black relative mt-10 sm:mt-10 md:mt-0  flex-col  justify-center  md:mx-3 md:w-5/12  lg:flex'>
                            {/* project Image */}
                            <div className='absolute right-0 transform -translate-y-1/2 top-1/2 '>
                                <img className='transition-transform duration-300 transform scale-50 opacity-50 group-hover:scale-95 sm:scale-100 sm:group-hover:scale-110 group-hover:opacity-100' src='images/ib.svg' alt='' width={230} height={230} />
                            </div>
                            <div className='flex-col pl-5 md:pl-4'>
                                {/* project Logo  */}
                                <div className='flex flex-row'>
                                    <div className='hidden sm:flex md:hidden'>
                                        <img src='images/iblogo.svg' alt='' width={100} height={100} className='group-hover:hidden' />
                                        <img src='images/iblogo2.svg' alt='' width={100} height={100} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden md:grid xl:hidden'>
                                        <img src='images/iblogo.svg' alt='' width={80} height={80} className='group-hover:hidden' />
                                        <img src='images/iblogo2.svg' alt='' width={80} height={80} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden xl:grid'>
                                        <img src='images/iblogo.svg' alt='' width={120} height={120} className='group-hover:hidden' />
                                        <img src='images/iblogo2.svg' alt='' width={120} height={120} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='sm:hidden'>
                                        <img src='images/iblogo.svg' alt='' width={90} height={90} className='group-hover:hidden' />
                                        <img src='images/iblogo2.svg' alt='' width={90} height={90} className='hidden group-hover:grid' />
                                    </div>
                                </div>

                                {/* Project Description */}
                                <div className='sm:w-[400px]  sm:pt-3 text-black group-hover:text-white sm:text-lg md:text-sm md:w-[200px] xl:text-base xl:w-[260px] mt-2 text-sm sm:pr-0 pr-20 '>
                                    I conceptualized and designed a dynamic web landing page aimed at driving sales for a specific product. The landing page serves as the initial point of contact for potential...
                                </div>

                                {/* Social Media Icons */}
                                <div className='flex-row hidden pt-4 sm:flex md:hidden'>
                                    <a href='https://www.behance.net/gallery/188126183/Landing-Page-Design'><img src='images/Git.svg' alt='' width={30} height={30} className='mr-1 group-hover:hidden' /></a>
                                    <a href='https://www.behance.net/rotaframe'><img src='images/Behance.svg' alt='' width={30} height={30} className='group-hover:hidden' /></a>
                                    <a href='https://www.behance.net/gallery/188126183/Landing-Page-Design'><img src='images/Git1.svg' alt='' width={30} height={30} className='hidden mr-1 group-hover:grid' /></a>
                                    <a href='https://www.behance.net/rotaframe'><img src='images/Behace1.svg' alt='' width={30} height={30} className='hidden group-hover:grid' /></a>
                                </div>
                                <div className='hidden md:flex'>
                                    <div className='flex flex-row pt-4'>
                                        <a href='https://www.behance.net/gallery/188126183/Landing-Page-Design'><img src='images/Git.svg' alt='' width={30} height={30} className='mr-1 group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/rotaframe'><img src='images/Behance.svg' alt='' width={30} height={30} className='group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/gallery/188126183/Landing-Page-Design'><img src='images/Git1.svg' alt='' width={30} height={30} className='hidden mr-1 group-hover:grid' /></a>
                                        <a href='https://www.behance.net/rotaframe'><img src='images/Behace1.svg' alt='' width={30} height={30} className='hidden group-hover:grid' /></a>
                                    </div>
                                </div>
                                <div className='sm:hidden'>
                                    <div className='flex flex-row pt-2'>
                                        <a href='https://www.behance.net/gallery/188126183/Landing-Page-Design'><img src='images/Git.svg' alt='' width={25} height={25} className='mr-1 group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/rotaframe'><img src='images/Behance.svg' alt='' width={25} height={25} className='group-hover:hidden' /></a>
                                        <a href='https://www.behance.net/gallery/188126183/Landing-Page-Design'><img src='images/Git1.svg' alt='' width={25} height={25} className='hidden mr-1 group-hover:grid' /></a>
                                        <a href='https://www.behance.net/rotaframe'><img src='images/Behace1.svg' alt='' width={25} height={25} className='hidden group-hover:grid' /></a>
                                    </div>
                                </div>

                                {/* Explore More part */}
                                <div className='flex flex-row items-center pt-2 sm:pt-3'>
                                    <div className='mr-2 text-sm text-black sm:text-base group-hover:text-white md:text-sm xl:text-base'><a href='https://www.behance.net/gallery/188126183/Landing-Page-Design'>Explore more</a></div>
                                    <div className='hidden sm:flex md:hidden'>
                                        <img src='images/arrow.svg' alt='' width={20} height={20} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={20} height={20} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden md:flex xl:hidden'>
                                        <img src='images/arrow.svg' alt='' width={15} height={15} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={15} height={15} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='hidden xl:flex'>
                                        <img src='images/arrow.svg' alt='' width={18} height={18} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={18} height={18} className='hidden group-hover:grid' />
                                    </div>
                                    <div className='sm:hidden'>
                                        <img src='images/arrow.svg' alt='' width={15} height={15} className='group-hover:hidden' />
                                        <img src='images/arrow1.svg' alt='' width={15} height={15} className='hidden group-hover:grid' />
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className='hidden sm:w-11/12 w-11/12 h-[50px] sm:h-[70px] md:h-[300px] px-7 md:px-0 md:ml-3 md:flex relative group mx-5 bg-white hover:opacity-50 rounded-lg overflow-hidden mt-10 md:mt-0 flex-col  justify-center items-center md:mx-3 md:w-1/12 md:mr-16'>
                            <div className='flex flex-col items-center justify-center w-full h-full font-semibold text-black bg-white rounded-lg md:text-lg md:mt-0 sm:text-xl md:w-6/12 md:h-full'>
                                <div className='md:rotate-90 md:transform whitespace-nowrap'>
                                    View All Projects
                                </div>
                            </div>


                        </div>

                        {/* <div className='sm:w-full sm:h-[70px] lg:h-[300px] px-7 lg:px-0 lg:ml-3 lg:w-1/12 flex justify-end lg:mr-20 relative'>
                        <div className='flex flex-col items-center justify-center w-full h-full mt-5 font-semibold text-black bg-white rounded-lg lg:text-lg lg:mt-0 sm:text-xl lg:w-6/12 lg:h-full'>
                            <div className='lg:rotate-90 lg:transform whitespace-nowrap'>
                                View All Projects
                            </div>
                        </div>
                    </div> */}


                    </div>
                </div>

            </div>
        </section>

    )
}

export default Portfolio