import React from 'react';
import Marquee from "react-fast-marquee";

function LogoSlider() {
  return (
    <div className='flex flex-row items-center justify-center w-screen'>
      <div className='flex flex-row w-screen md:w-[1440px] mt-[200px] md:mt-[300px] justify-center'>
        {/* Icon slider start */}
        <div className="justify-center hidden ml-10 mr-10 bg-black sm:grid lg:flex md:w-[1440px]">
          <Marquee direction="left" speed={20} gradient gradientColor="black" className='mb-8'>

            <img src="images/asp.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/woo.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/wordpress.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/Figma.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/framer.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/Photoshop.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/Illustrator.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/React.svg" alt="" className="w-20 h-20 mx-8" />
            <img src="images/Next.svg" alt="" className="w-20 h-20 mx-8" />
            <img src="images/view.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/Flutter.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/swift.png" alt="" className="w-20 h-20 mx-8" />

            <img src="images/asp.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/woo.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/wordpress.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/Figma.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/framer.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/Photoshop.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/Illustrator.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/React.svg" alt="" className="w-20 h-20 mx-8" />
            <img src="images/Next.svg" alt="" className="w-20 h-20 mx-8" />
            <img src="images/view.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/Flutter.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/swift.png" alt="" className="w-20 h-20 mx-8" />

            <img src="images/asp.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/woo.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/wordpress.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/Figma.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/framer.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/Photoshop.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/Illustrator.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/React.svg" alt="" className="w-20 h-20 mx-8" />
            <img src="images/Next.svg" alt="" className="w-20 h-20 mx-8" />
            <img src="images/view.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/Flutter.png" alt="" className="w-20 h-20 mx-8" />
            <img src="images/swift.png" alt="" className="w-20 h-20 mx-8" />

          </Marquee>
        </div>



       
        {/* Icon slider end */}

        <div className="w-screen bg-black sm:hidden">
          <Marquee direction="left" speed={20} gradient gradientColor="black" className='mb-8'>

            <img src="images/asp.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/woo.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/wordpress.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/Figma.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/framer.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/Photoshop.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/Illustrator.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/React.svg" alt="" className="w-16 h-16 mx-8" />
            <img src="images/Next.svg" alt="" className="w-16 h-16 mx-8" />
            <img src="images/view.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/Flutter.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/swift.png" alt="" className="w-16 h-16 mx-8" />

            <img src="images/asp.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/woo.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/wordpress.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/Figma.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/framer.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/Photoshop.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/Illustrator.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/React.svg" alt="" className="w-16 h-16 mx-8" />
            <img src="images/Next.svg" alt="" className="w-16 h-16 mx-8" />
            <img src="images/view.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/Flutter.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/swift.png" alt="" className="w-16 h-16 mx-8" />

            <img src="images/asp.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/woo.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/wordpress.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/Figma.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/framer.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/Photoshop.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/Illustrator.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/React.svg" alt="" className="w-16 h-16 mx-8" />
            <img src="images/Next.svg" alt="" className="w-16 h-16 mx-8" />
            <img src="images/view.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/Flutter.png" alt="" className="w-16 h-16 mx-8" />
            <img src="images/swift.png" alt="" className="w-16 h-16 mx-8" />

          </Marquee>

          


        </div>

        
      </div>
      
    </div>
  );
}

export default LogoSlider